@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Caveat:wght@500&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Kanit:wght@500;600&family=Poppins:wght@500&display=swap');

.signup-con {
    box-shadow: 1px 5px 20px 0px #DCD9D940;
    color: rgb(41, 174, 227);

}

.shdo {
    box-shadow: 1px 5px 20px 0px #DCD9D940;
}

.nav-con-top {
    box-shadow: 0px 2px 2px 0px #28A5C133;
    background-color: white;
}


.inputField {
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #7C7F82;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Poppins', sans-serif;
}
