 

 

.customer-details{
    font-size: 18px;
}


.input-firstname{
    margin:15px;
    border-radius:5px;
    width:230px;
    height:35px;
}

 

.email-input-box{
    margin:15px;
    border-radius:5px;
    width:340px;
    height:35px;
}

.card{
    border:1px solid lightslategray;
}

.text-align{
    text-align: end;
}

 

.bids-card{
    width:100%;
    height: 23vh;
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background-color: white;
}

.bids-card:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 0.3s;
}

.card-container{
    margin-bottom:0px;
    padding-bottom:0px;
    min-height: 55vh;
    background: #eceff1;
    background: linear-gradient(15deg, rgb(224,224,224) 0%, rrgb(233, 237, 254)100%)  ;
}

p{
    font-family: "Roboto";
}

