label {
  position: relative;
  top: 0.7rem;
  font-size: 1rem;
  color: #131344;
}

/* ========================== commun css style ====================================================== */
.signup-main-container {
  height: auto;
  padding: 0.4rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -2.4rem;
  border-radius: 0.5rem;

}

::placeholder {
  color: rgb(112, 114, 114);
  opacity: 0.5;
  font-weight: 600;
}

.signupPageChangeBtn {
  height: 3rem;
  color: rgb(13, 187, 245);
  background-color: none;
  border: none;
  margin: 0.3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: bold;
  border-radius: 0.5rem;
}

.signupPageChangeBtn:active {
  box-shadow: none;
}

/* navigation style css ================================================================ */




.signupButton {
  height: 3rem;
  width: 9rem;
  border: none;
  border-radius: 0.3rem;
  background-color: rgb(13, 187, 245);
  color: rgb(216, 218, 216);
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.signupButton:hover {
  background-color: rgb(43, 43, 105);
  transition: 0.6s;
}

.nav_container {
  background-color: #e7e7f3;
  z-index: 5;
  opacity: 0.9;
}


.add-vehicle {
  -webkit-animation-name: add-vehicle;
  animation-name: add-vehicle;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes add-vehicle {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes add-vehicle {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

/* ================================ add Driver =============================*/

.addDriver {
  -webkit-animation-name: addDriver;
  animation-name: addDriver;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes addDriver {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes addDriver {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.vsucc {
  height: 4rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #12124e;
  font-weight: bold;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  border-left: 9px solid green;
  margin-bottom: 0.6rem;
  background-color: rgb(223, 243, 223);
}

@-webkit-keyframes vsucc {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes vsucc {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.vsuccErr {
  -webkit-animation-name: vsuccErr;
  animation-name: vsuccErr;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes vsuccErr {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes vsuccErr {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@media(max-width:700px) {

  .sccmsg,
  .sccerr {
    font-size: 0.7rem;
  }
}

/* ========================================================================================================= */

.signup-form-container {
  background-color: white;
  margin-top: 1rem;
  border: 1.2px solid #c7c7ca;
  margin-bottom: 6rem;
  border-radius: 0.3rem;
}

.signup-input-field {
  width: 100%;
  height: 3.1rem;
  margin-top: 1.2rem;
  outline: none;
  border: none;
  background-color: #f8f8fa;
  outline-width: 0.1rem;
  outline-color: none;
  padding-top: 0.14rem;
  padding-left: 2rem;
  color: rgb(167, 165, 165);
  font-weight: bold;
  border-radius: 0.3rem;
  border: 0.8px solid #b0b0b1;
}

body {
  opacity: 1;
  min-height: 100vh;
  background: #eceff1;
  background: linear-gradient(15deg, rgb(224, 224, 224) 0%, rrgb(233, 237, 254)100%);
}

.signupBtn {
  border: none;
  margin-top: 0.8rem;
  height: 2.9rem;
  width: 20%;
  margin-right: 0.3rem;
  background-color: #12124e;
  color: white;
  font-weight: bold;
  border-radius: 0.5rem;
}

.signup-input-field {
  -webkit-animation-name: signup-input-field;
  animation-name: signup-input-field;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes signup-input-field {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes signup-input-field {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.signup-form-container {
  -webkit-animation-name: signup-form-container;
  animation-name: signup-form-container;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes signup-form-container {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes signup-form-container {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.signup-form-container {
  -webkit-animation-name: signup-form-container;
  animation-name: signup-form-container;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes signup-form-container {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes signup-form-container {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}


.dashBox {
  background-color: #e4e4fa;
  border-radius: 0.3rem;
  padding: 0.8rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  opacity: 0.9;
  border: none;
}

.WeldashBox {
  background-color: #e4e4fa;
  border-radius: 0.3rem;
  padding: 0.8rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  opacity: 0.9;
}

.dashBox:hover {
  box-shadow: none;
  opacity: 2;
  cursor: pointer;
}

.WeldashBox:hover {
  box-shadow: none;
  opacity: 2;
  cursor: pointer;
}


.dashBox {
  -webkit-animation-name: dashBox;
  animation-name: dashBox;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes dashBox {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes dashBox {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.WeldashBox {
  -webkit-animation-name: WeldashBox;
  animation-name: WeldashBox;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes WeldashBox {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes WeldashBox {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.sdt {
  font-family: 'Dancing Script', cursive;
  font-family: 'Oswald', sans-serif;
  text-align: center;
}


.signup-dash {
  background-color: white;
  margin-top: 3rem;
  border-top: 4px solid #181849;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.cnc {

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}


.svd {
  background-color: white;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  margin-bottom: 1rem;
  position: relative;
  margin-top: 9rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 0.3rem;
}

.svd {
  -webkit-animation-name: svd;
  animation-name: svd;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes svd {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes svd {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.gstBtnNo,
.gstBtnYes {
  height: 3rem;
  width: 45%;
  background-color: #e4e4fa;
  border: none;
  border-radius: 0.5rem;
  font-weight: bold;
}

.gstBtnNo:hover,
.gstBtnYes:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}


.signup-input-otp {
  -webkit-animation-name: signup-input-otp;
  animation-name: signup-input-otp;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes signup-input-otp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes signup-input-otp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.custom-file-input::-webkit-file-upload-button {
  background-color: #181849;
  padding: 0.46rem;
  border-radius: 0.5rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.custom-file-input {
  padding-left: 0rem;
}

.login-color-col {
  background: rgb(18, 172, 231);
  background: linear-gradient(90deg, rgb(185, 194, 56) 0%, rgb(104, 172, 199));
  border-radius: 0.4rem;
  opacity: 0.8;
}

.login-form-container {
  border-radius: 0.3rem;
  background-color: white;
}

.tHead {
  border-bottom: 3px solid #ebe9e9;
}

.tBody {
  background-color: #e4e4fa;
  padding: 0.4rem;
  padding-top: 0.96rem;
  border-radius: 0.5rem;
  margin-top: 0.7rem;
  opacity: 0.9;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.tBody:hover {
  box-shadow: none;
  opacity: 1.4;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.actionbtn {
  height: 2.4rem;
  border-radius: 0.4rem;
  background-color: rgb(238, 237, 237);
  color: #181849;
  border: none;
  padding: 0.3rem;
}

.tBox {
  width: 15rem;
}

.tBodyBox {
  width: 19rem;
}

.thed {
  margin: auto;
  font-size: 1.1rem;
  font-weight: bold;
  color: #12124e;
}

.tbod {
  color: #12124e;
  font-size: 0.9rem;
  font-weight: bold;
}

.uinfo {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 0.3rem;
}

.uinfo {
  -webkit-animation-name: uinfo;
  animation-name: uinfo;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes uinfo {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes uinfo {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}



.topNav {
  width: 100%;
  z-index: 10;
}





.sideLink {
  font-size: 1.2rem;
  text-decoration: none;
  position: relative;
  color: rgb(13, 187, 245);
  font-weight: 600;
  width: 100%;
  padding-left: 0.4rem;
}

.sideLink:hover {
  color: rgb(13, 187, 245)
}

.sideLinkCon1 {
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.sideLinkCon1:hover {
  background-color: rgb(235, 233, 232);
  transition: 0.5s;
  border-radius: 0.4rem;
}

.profileSubMenu {
  position: relative;
  top: 0rem;
  width: 100%;
  background-color: #e4e4fa;
  border-radius: 0.4rem;
  padding: 0.6rem;
}

.profileSubMenu {
  -webkit-animation-name: profileSubMenu;
  animation-name: profileSubMenu;
  -webkit-animation-duration: 0.80s;
  animation-duration: 0.80s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes profileSubMenu {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes profileSubMenu {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


.sideLinkSub {
  color: rgb(13, 187, 245);
  font-weight: 500;
}

.prifileBtn {
  height: 2.4rem;
  width: 13rem;
  color: #12124e;
  border: none;
  border-radius: 0.2rem;
  border-top: 2px solid #12124e;
  position: relative;
  top: 9rem;
}


.bidsContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  position: absolute;
  top: 0px;
  right: 2px;
}

.prifileBtnCon {
  position: relative;
  top: 10rem;
  background-color: rgb(223, 222, 221);
  width: 13rem;
  border-radius: 0.2rem;
}

.profileLink {
  width: 100%;
  padding: 0.7rem;
  margin-top: 2rem;
}

.topNav {
  border-radius: 0rem;
}

.profileLink:hover {
  background-color: #fff;
  border-radius: 0.3rem;
}

.profileContainer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 21rem;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  border-radius: 0.3rem;
  z-index: 10;
}

.profileContainer {
  -webkit-animation-name: profileContainer;
  animation-name: profileContainer;
  -webkit-animation-duration: 0.80s;
  animation-duration: 0.80s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes profileContainer {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes profileContainer {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


.mainSerch {
  z-index: 15;
  height: 3.7rem;
  background-color: #ebebee;
  border-radius: 0.4rem;
  border: none;
  outline-style: none;
  outline-width: none;
  outline: none;
  width: 28rem;
  padding-left: 1.8rem;
  color: #12124e;
  display: none;
}

.profileLink {
  text-decoration: none;
  font-size: 1.2rem;
  color: rgb(13, 187, 245);
  margin-top: 2rem;
  font-weight: bold;
}

.profileSubMenu {
  position: relative;
  background-color: #e5e5e7;
  width: 100%;
  margin-top: 0.3rem;
}

.profileSubMenu:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
  transition: 0.4s;
}

.profileLink:hover {
  color: rgb(228, 244, 250);
  cursor: pointer;
}

.bidsLink {
  text-decoration: none;
  font-size: 1.2rem;
  color: rgb(13, 187, 245);
  width: 100%;
  font-weight: bold;
  padding: 0.7rem;

}

.bidsLink:hover {
  color: rgb(13, 187, 245);
  cursor: pointer;
}

.profileContainerHeader {
  height: 7rem;
  width: 100%;
  background-color: #ebebee;
}


.gstSubMenu {
  width: 100%;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #e7e7f3;
  margin-top: 0.9rem;
  border-radius: 0.4rem;
}

.udc {
  border-radius: 0.4rem;
}

.ibst {
  position: relative;
  top: 0rem;
  z-index: 999;
}

.close-pc-outer {
  position: absolute;
  top: 2rem;
  right: -0.7rem;
  width: 2.2rem;
  height: 2.2rem;
  background-color: rgb(228, 226, 226);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  border: none;
}

.close-pc-inner {
  position: relative;
  right: -0.1rem;
  width: 1.4rem;
  height: 1.4rem;
  background-color: #12124e;
  border-radius: 50%;
  cursor: pointer;
}


.cpc {
  font-size: 0rem;
  position: absolute;
  top: 2.4rem;
  right: 0rem;
  border-radius: 0.3rem;
  cursor: pointer;
  z-index: 40;
  background-color: #e4e4fa;
}

.bars {
  font-size: 0rem;
  cursor: pointer;
  z-index: 40;
  position: relative;
  top: -0.3rem;
  left: -0.7rem;
}

.topNavCon {
  padding-left: 5rem;
}

@media(max-width:1400px) {

  .cpc,
  .bars {
    font-size: 1.6rem;
  }

  .topNavCon {
    padding-left: 0rem;
  }
}

@media(max-width:1612px) {
  .profileContainer {
    width: 18.4rem;
  }
}

.profileSetting {
  height: 18rem;
  width: 12rem;
  background-color: #fdfafa;
  position: absolute;
  right: 3.7rem;
  top: 4.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 0.3rem;
}

.profileSetting {
  -webkit-animation-name: profileSetting;
  animation-name: profileSetting;
  -webkit-animation-duration: 0.13s;
  animation-duration: 0.13s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes profileSetting {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes profileSetting {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}


.bids-count {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  border-radius: 0.4rem;
  height: 17rem;
  padding: 1rem;
}

.bidIconcon {
  width: 3.88rem;
  background-color: #effae4;
  border-radius: 0.4rem;

}

.user-docs {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 1rem;
  height: 37rem;
}

.bids-count:hover {
  box-shadow: none;
  opacity: 0.9;
}

.TopNavSignupButton {
  background-color: #12124e;
  border-radius: 2rem;
  border: none;
  border: 3px solid #12124e;
  font-weight: 600;
  padding: 0.7rem;
  color: white;
}

.TopNavSignupButton:hover {
  background-color: white;
  color: #12124e;
  transition: 0.7s;
}

.TopNavSignupButton1:hover {
  background-color: #1f1f46;
  color: white;
  transition: 0.7s;
}

.TopNavSignupButton1 {
  border-radius: 2rem;
  border: 3px solid #12124e;
  font-weight: 600;
  padding: 0.7rem;
  color: #12124e
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Nunito+Sans:wght@300&family=Nunito:wght@200;300&family=Oswald:wght@300;600&display=swap');

.registerHeading {
  font-family: 'Dancing Script', cursive;
  font-family: 'Nunito', sans-serif;
  font-family: 'Nunito Sans', sans-serif;
  font-family: 'Oswald', sans-serif;
  font-size: 180%;
  color: rgb(250, 184, 62);
  font-weight: 600;
  text-align: center;
}

.hp {
  font-family: 'Dancing Script', cursive;
  font-family: 'Nunito', sans-serif;
  font-family: 'Nunito Sans', sans-serif;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
}

@media(max-width:500px) {
  .registerHeading {
    font-size: 120%;
  }

  .signup-input-field {
    height: 2.3rem;
  }

  .hp {
    font-size: 0.8rem;
    text-align: center;
  }

  .signupBtn {
    border-radius: 2rem;
    height: 2.3rem;
    width: 40%;
  }
}

.bids {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: white;
  border-radius: 0.8rem;
  margin-top: 0.8rem;
}

.bidsBtn {
  position: relative;
  top: -1.5rem;
  height: 2rem;
}

.bidsConatiner {
  padding: 0rem;
  background-color: #fff;
  margin-top: 5rem;
  height: 38rem;
  overflow-y: scroll;
  border-radius: 0.2rem;
}

.payment {
  height: 50rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: white;
  border-radius: 0.6rem;
  position: relative;
}


.footer {
  background-color: #020222;
  padding-top: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
}

.icons-container:hover>.icons {
  -webkit-animation-name: icons;
  animation-name: icons;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}




@-webkit-keyframes icons {

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes icons {

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pieChart {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 0.4rem;
  justify-content: center;
  align-items: center;
}

.signup-container {
  box-shadow: rgba(2, 0, 0, 0.05) 0px 4px 16px, rgba(4, 0, 2, 0.02) 0px 0px 0.3px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.error1 {
  padding-top: 1.4rem;
  padding-bottom: 1rem;
  background-color: rgb(255, 233, 233);
  border-radius: 0.4rem;
  padding-left: 0.7rem;
  position: relative;
  width: 60%;
}

.errorgst {
  padding-top: 1.4rem;
  padding-bottom: 1rem;
  background-color: rgb(255, 233, 233);
  border-radius: 0.4rem;
  padding-left: 0.7rem;
  position: relative;
  width: 80%;
}

.nav-link,
span {
  font-size: 1.3rem;
}

.tbl-con {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 1rem;
  position: relative;
  top: 4rem;
}

.tbl-con,
tr,
td {
  font-size: 1.21rem;
  font-weight: 500;
  opacity: 1;
  color: #575758;
}

.tbl-con,
tr,
th {
  font-size: 1.3rem;
  font-weight: 700;
  opacity: 1;
  color: #575758;
}


.pgntionBtn {
  border-radius: 1.2rem;
  border: none;
  height: 2.1rem;
  background-color: #e6e6fd;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 450;
}

.updateKyc {
  height: 40rem;
  border-radius: 0.4rem;
  background-color: white;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}

.updateKycMenu {
  height: 40rem;
  border-radius: 0.4rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


.kyc-link {
  list-style: none;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  width: 100%;
}

.kyc-link:hover {
  background-color: #e4e4fa;
  cursor: pointer;
}

.updateKycBc {
  position: relative;
  top: 2rem;
}

.kycgst,
.brokerKyc {
  position: relative;
  top: 3rem;
}

.kyc-heading {
  position: fixed;
  top: 1rem;
  left: 1rem;
  font-weight: 1600;
}

.profile {
  height: 38rem;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: 4rem;
}

.creacte-bids {
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  margin-top: 2.5rem;
  border: 0.3px solid lightgray;
}


.shake:hover {
  -webkit-animation-name: shake;
  animation-name: shake;
  -webkit-animation-duration: 0.20s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes shake {

  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
}

@keyframes shake {

  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
}


.App {
  text-align: center;
}

.target {
  font-size: 1.5em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Bar {
  position: relative;
  z-index: 100000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width: 100vw;
  height: 100vh;
}

/**
      * The styles setting up the images below were added to this demo
      * to avoid import issues when embedded with Codesandbox.
      */

.leaflet-container .leaflet-control-fullscreen a {
  background-image: url("./Container/GlobleImageContainer/images/dl.jpeg");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .leaflet-container .leaflet-control-fullscreen a {
    background-image: url("./Container/GlobleImageContainer/images/dl.jpeg");
  }
}

.modal {
  font-size: 12px;
  max-height: 90vh;
  max-width: 90vw;
}

.modal>.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal>.content {
  width: 100%;
  padding: 10px 5px;
  padding-top: 40px;
}

.modal>.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.mapContainer {

  height: 91vh;
  position: relative;
}

.durationAndDistance {
  background-color: white;
  border: 0.3px solid lightgray;
  position: absolute;
  top: 4rem;
  z-index: 10;
  left: 0.3rem;
  right: 0.3rem;
  padding: 0.5rem;
}

.location {
  height: 2.8rem;
  width: 80%;
  border: none;
  outline: none;
  outline-color: #2c2c6c;
  border: 0.6px solid #e4e4fd;
  border-radius: 0.3rem;
}

.calBtn {
  height: 2.5rem;
  border: none;
  outline: none;
}

.new {
  color: rgb(83, 83, 83);
}


.profile1 {
  height: 30rem;
}

.profile1 {
  height: 33rem;
}

::-webkit-scrollbar {
  width: 9px;
  border-radius: 0.6rem;
  overflow-y: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;


}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.customer-top-box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-top-right-radius: 2.4rem;
  border-bottom-left-radius: 2.4rem;
}


.no-args {
  background: #eceff1;
  background: linear-gradient(15deg, rgb(224, 224, 224) 0%, rrgb(233, 237, 254)100%);
}


.cr_card {
  background: rgb(24, 13, 235);
  background: linear-gradient(90deg, rgba(24, 13, 235, 1) 0%, rgba(13, 66, 235, 1) 58%);
}

.acc_card {
  background: rgb(12, 9, 74);
  background: linear-gradient(90deg, rgba(12, 9, 74, 1) 0%, rgba(13, 159, 235, 1) 38%);
}

.succ_card {
  background: rgba(28, 138, 32, 0.676);
  background: linear-gradient(90deg, rgba(52, 160, 55, 0.676) 0%, rgba(61, 175, 65, 0.676) 9%);
}


.exp_card {
  background-color: gray;
}

.login-main-container {
  min-height: 100vh;
  margin-top: 0;
  background: #eceff1;
  background: linear-gradient(15deg, rgb(224, 224, 224) 0%, rrgb(233, 237, 254)100%);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.response-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;

}

.menu {
  margin-right: 25px;
  width: 50px;
  height: 30px;
}

.round-circle {
  border: 1px solid #282c34;
  border-collapse: separate;
  border-right: none;
  border-radius: 50%;
  height: 65px;

}

.img-card {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.promo-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-color: #282c34;
  margin-bottom: 40px;
}

.content-card {
  border: 1px solid #282c34;
  border-left: none;
  border-collapse: collapse;
  border-radius: 3px;
  width: 450px;
  height: 45px;
  color: white;
  margin-top: 10px;
  padding-left: 30px;
  padding-top: 8px;
  margin-left: 0px;
}

.flex-card {
  display: flex;
  margin-top: 0px;
}


.card-search {
  border-top-left-radius: 8px;
}

.card-content {
  padding: 15px;
  background-color: rgb(40, 251, 251);
  border-radius: 20% 10% 10% 40%;
  width: 450px;
  height: 45px;
  margin-left: 25px;
}

.searchBtn {
  height: 3rem;
  background-color: white;
  border: 1px solid #020222;
  border-radius: 0.08rem;
  width: 9rem;
  color: #020222;
}

.searchBtn:hover {
  background-color: #0d0d4a;
  color: white;
  border: 1px solid white;
  transition: 0.6s;
}

.carousel-caption {
  top: 0;
  bottom: auto;

}

.TableHover:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.2s;
  color: rgb(246, 235, 174);
}

.iconHover:hover {
  background-color: rgb(227, 239, 249);
  transform: 0.13s;
  cursor: pointer;
}

.text {
  color: hsl(0, 10%, 96%);
}

.card1 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(16, 34, 36, 0.22) 0px 10px 10px;

}

.location-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.kycAlertCus {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.menuButton:hover {
  background-color: white;
  border-left: 5px solid rgb(68, 67, 67);
  transition: 0.5s;
}

.reportsBox {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}