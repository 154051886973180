.dashboard-container{
    display:flex;
    align-items: center;
}

.break-line{
    border:1px solid darkblue;
    width:450px;
}

.customer-details{
    font-size: 18px;
}

.profile-card{
    width:450px;
    height:480px;
}

.profile-icon{
    width:100px;
}



.input-firstname{
    margin:15px;
    border-radius:3px;
    width:230px;
    height:45px;
}

.input-secondname{
    margin:15px;
    margin-left:5px;
    border-radius:3px;
    width:230px;
    height:42px;
}

.email-input-box{
    margin:15px;
    border-radius:3px;
    width:360px;
    height:42px;
}

.profileBtn{
    width:11rem;
    height:2.6rem;
    border:2px solid rgb(43, 43, 105);
    background-color:white;
    color:rgb(43, 43, 105);
}

.profileBtn:hover{
    height:2.6rem;
    border:2px solid rgb(43, 43, 105);
    background-color:rgb(43, 43, 105);
    color:white;
    transition: 0.7s;
}