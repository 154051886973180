 

 

.customer-details{
    font-size: 18px;
}


.input-firstname{
    margin:15px;
    border-radius:5px;
    width:230px;
    height:35px;
    
    
}

 

.email-input-box{
    margin:15px;
    border-radius:5px;
    width:340px;
    height:35px;
}

.text-align{
    text-align: end;
}


.bids-card1{
    width:100%;
    height: 23vh;
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    position: relative;
}

.bids-card1:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 0.3s;
}

.card-container{
    margin-bottom:0px;
    padding-bottom:0px;
    background: #eceff1;
    background: linear-gradient(15deg, rgb(224,224,224) 0%, rrgb(233, 237, 254)100%)  ;
}

p{
    font-family: "Roboto";
}

.card1 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
